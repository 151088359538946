import {
  Box,
  Button,
  Grid,
  type GridProps,
  IconButton,
  styled,
  SxProps,
  useTheme,
} from "@mui/material";
import { Layout, PimoReactLayout } from "@pimo/pimo-app-builder";
import { ReactNode, useState } from "react";

export type GridLayoutProps = GridProps;

const Stage = styled(Box, {
  shouldForwardProp: (prop) => prop !== "enablePageBreak",
})<{ enablePageBreak: boolean }>(({ theme, enablePageBreak }) => ({
  flexGrow: 1,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.spacing(1.5),
  "@media print": {
    backgroundColor: "unset",
    pageBreakBefore: enablePageBreak ? "always" : "avoid",
  },
}));

/**
 * Layout that display components in three rows. Use the `row` layout property
 * when calling `addComponent` to define which component goes where.
 */
const buildGridLayoutReactComponent: (
  spacing: number,
  enablePageBreak: boolean,
  paddingX: number,
  buttons?: {
    icon?: ReactNode | string;
    text?: string;
    name?: string;
    sx?: SxProps;
    onClick: () => void;
  }[],
  backgroundColor?: string
) => PimoReactLayout<GridLayoutProps> =
  (spacing, enablePageBreak, paddingX, buttons, backgroundColor) =>
  ({ components }) => {
    const theme = useTheme();

    const [activeButton, setActiveButton] = useState("default");

    return (
      <Stage
        enablePageBreak={enablePageBreak}
        sx={{
          padding: theme.spacing(spacing),
          mb: theme.spacing(spacing),
          px: theme.spacing(paddingX),
          backgroundColor,
        }}
      >
        {buttons && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0,
                py: "10px",
                "@media print": {
                  display: "none",
                },
              }}
            >
              <Box sx={{ display: "flex", gap: 0 }}>
                {buttons.map((button) =>
                  typeof button.icon === "string" ? (
                    <Button
                      key={button.text}
                      startIcon={
                        <Box
                          component={"img"}
                          src={button.icon}
                          sx={{ margin: 0, padding: 0 }}
                        />
                      }
                      onClick={button.onClick}
                      sx={{
                        padding: "4px 8px",
                        color: theme.palette.primary.dark,
                        borderColor: theme.palette.primary.dark,
                        borderRadius: "4px",
                        margin: 0,
                        ...button.sx,
                      }}
                    >
                      {button.text}
                    </Button>
                  ) : (
                    <IconButton
                      size="small"
                      color={
                        activeButton === button.name ? "primary" : "default"
                      }
                      onClick={() => {
                        setActiveButton(button.name ?? "default");
                        button.onClick();
                      }}
                    >
                      {button.icon}
                    </IconButton>
                  )
                )}
              </Box>
            </Box>
          </Box>
        )}

        <Grid container spacing={spacing}>
          {components.map((component, index) => {
            const Component = component?.render?.();

            return (
              <Grid item key={`grid_item_${index}`} {...component.layoutProps}>
                <Component />
              </Grid>
            );
          })}
        </Grid>
      </Stage>
    );
  };

export class GridLayout implements Layout<GridLayoutProps> {
  constructor(
    private spacing = 1,
    private enablePageBreak = false,
    private paddingX = 1,
    private buttons?: {
      icon?: ReactNode | string;
      text?: string;
      name?: string;
      sx?: SxProps;
      onClick: () => void;
    }[],
    private backgroundColor?: string
  ) {}

  getLayoutComponent() {
    return buildGridLayoutReactComponent(
      this.spacing,
      this.enablePageBreak,
      this.paddingX,
      this.buttons,
      this.backgroundColor
    );
  }
}

export default GridLayout;
