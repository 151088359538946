import { Search as SearchIcon } from "@mui/icons-material";
import { InputBase, InputBaseProps, styled } from "@mui/material";
import { ChangeEvent, FC } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#FAFAFA",
  "&:hover": {
    backgroundColor: "#FCFCFC",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme, color }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: color || "#616161",
}));

interface CustomInputBaseProps extends InputBaseProps {
  placeholderTextColor?: string;
  makePlaceholderBold?: boolean;
}

const StyledInputBase = styled(InputBase, {
  shouldForwardProp: (prop) =>
    prop !== "placeholderTextColor" && prop !== "makePlaceholderBold",
})<CustomInputBaseProps>(
  ({ theme, placeholderTextColor, makePlaceholderBold }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
      "&::placeholder": {
        fontWeight: 500,
        color: placeholderTextColor || "#616161",
        opacity: makePlaceholderBold ? 1 : 0.5,
      },
    },
  })
);

export interface SearchBarProps {
  onChange: (value: string) => void;
  searchIconColor?: string;
  placeholderTextColor?: string;
  makePlaceholderBold?: boolean;
}

export const SearchBar: FC<SearchBarProps> = ({
  onChange,
  searchIconColor,
  placeholderTextColor,
  makePlaceholderBold = false,
}) => {
  return (
    <Search>
      <SearchIconWrapper color={searchIconColor}>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholderTextColor={placeholderTextColor}
        makePlaceholderBold={makePlaceholderBold}
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
      />
    </Search>
  );
};
