import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { PimoButton } from "@pimo/pimo-components";
import { GeneralSettingsEventPayload, QuestionnaireResponse } from "crq-types";
import { getNonUpToDateOENames } from "crq-utils";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

type GeneralSettingsEventNames =
  | "general-settings-changed"
  | "change-reporting-date";

type GeneralSettingsPayload = {
  reportingDate: string | null;
  questionnaires: QuestionnaireResponse[];
};

export const GeneralSettingsPage: PimoReactComponent<
  GeneralSettingsPayload,
  GeneralSettingsEventNames,
  GeneralSettingsEventPayload
> = ({ reportingDate, fireEvent, questionnaires }) => {
  const nonUpToDateOENames = getNonUpToDateOENames(questionnaires);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogFinishReportingPeriod, setOpenDialogFinishReportingPeriod] =
    useState(false);
  const [openDialogForceFinish, setOpenDialogForceFinish] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [selectedReportingDate, setSelectedReportingDate] = useState<
    string | null
  >(reportingDate);

  const [initialReportingDate, setInitialReportingDate] = useState<
    string | null
  >(reportingDate);

  useEffect(() => {
    setSelectedReportingDate(reportingDate);
    setInitialReportingDate(reportingDate);
  }, [reportingDate]);

  const hasChanges =
    !!selectedReportingDate &&
    !dayjs(selectedReportingDate).isSame(dayjs(initialReportingDate), "day");

  const handleSubmit = () => {
    fireEvent?.("general-settings-changed", {
      type: "starting-reporting-date",
      reportingDate: selectedReportingDate,
    });

    setInitialReportingDate(selectedReportingDate);
    setOpenDialog(false);
  };

  const handleFinishReportingPeriod = () => {
    fireEvent?.("general-settings-changed", {
      type: "end-reporting-date",
      reportingDate: null,
    });
    setSelectedReportingDate(null);
    setInitialReportingDate(null);
    setOpenDialogFinishReportingPeriod(false);
  };

  return (
    <Card sx={{ width: "100%", p: 2, boxShadow: "none" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h5">General Settings</Typography>
      </Stack>

      <Stack
        direction="column"
        spacing={1}
        alignItems="flex-start"
        mb={2}
        mt={4}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Setup Reporting Period
        </Typography>

        {reportingDate ? (
          <>
            <Stack
              spacing={1}
              alignItems="flex-start"
              sx={{
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>Status Reporting Period:</Typography>
                <Chip
                  label="active"
                  sx={{
                    backgroundColor: "#cdf4dc",
                    color: "#388E3C",
                    fontWeight: "bold",
                  }}
                />
              </Stack>

              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>Reporting Deadline:</Typography>
                <Chip
                  label={dayjs(reportingDate).format("DD-MM-YYYY")}
                  sx={{
                    backgroundColor: "#ede6f6",
                    color: "#673AB7",
                    fontWeight: "bold",
                  }}
                />

                <IconButton
                  onClick={() => {
                    setIsEditing(true);
                    setOpenDialog(true);
                  }}
                >
                  <CreateOutlinedIcon sx={{ color: "#003781" }} />
                </IconButton>
              </Stack>
            </Stack>

            {nonUpToDateOENames.length > 0 && (
              <Typography sx={{ fontWeight: "bold", marginBottom: "30px" }}>
                OEs without up to date data ({nonUpToDateOENames.length}):{" "}
                {nonUpToDateOENames.join(", ")}
              </Typography>
            )}

            <PimoButton
              text="Finish Reporting Period"
              onClick={() => {
                setOpenDialogFinishReportingPeriod(true);
              }}
              disabled={nonUpToDateOENames.length > 0}
            />

            {nonUpToDateOENames.length > 0 && (
              <PimoButton
                text="Force End Reporting Period"
                borderColor="#ff3333"
                customStyles={{ color: "#ff3333" }}
                onClick={() => setOpenDialogForceFinish(true)}
              />
            )}
          </>
        ) : (
          <>
            <Typography style={{ margin: "20px 0" }}>
              You have not currently setup a reporting period.
            </Typography>

            <PimoButton
              text="Setup Reporting Period"
              onClick={() => {
                setIsEditing(false);
                setOpenDialog(true);
              }}
            />
          </>
        )}
      </Stack>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="md"
        sx={{ margin: 8 }}
        PaperProps={{ sx: { padding: 2 } }}
      >
        <DialogTitle>
          {isEditing ? "Edit Reporting Deadline" : "Setup Reporting Period"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} mt={2}>
            {!isEditing && (
              <Typography>
                You are about to start a new update period for the CRQ input
                questionnaire. When you click “Start Reporting”, all OEs have to
                update the input questionnaire until the reporting deadline.
              </Typography>
            )}
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {isEditing ? "Reporting Deadline" : "Setup Reporting Period"}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={
                    selectedReportingDate ? dayjs(selectedReportingDate) : null
                  }
                  onChange={(date) => {
                    setSelectedReportingDate(
                      date ? date.format("YYYY-MM-DD") : null
                    );
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", paddingRight: 2 }}>
          <Button onClick={() => setOpenDialog(false)}>CLOSE</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            sx={{ ml: 2 }}
            disabled={!hasChanges}
          >
            {isEditing ? "Update Reporting Deadline" : "Start Reporting"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogFinishReportingPeriod}
        onClose={() => setOpenDialogFinishReportingPeriod(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Finish Reporting Period</DialogTitle>
        <DialogContent>
          <Typography>
            You are about to finish the update period for the CRQ input
            questionnaire. Please ensure all OEs have confirmed the input data
            is up-to-date. Clicking “Finish Reporting” will finalize the period.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            paddingRight: 2,
            marginTop: "20px",
          }}
        >
          <Button onClick={() => setOpenDialogFinishReportingPeriod(false)}>
            CLOSE
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            onClick={handleFinishReportingPeriod}
          >
            Finish Reporting
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogForceFinish}
        onClose={() => setOpenDialogForceFinish(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Force End Reporting Period</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to end the reporting period even though not
            all OEs have updated the data?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            paddingRight: 2,
            marginTop: "20px",
          }}
        >
          <Button onClick={() => setOpenDialogForceFinish(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            onClick={() => {
              handleFinishReportingPeriod();
              setOpenDialogForceFinish(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
