import {
  sortByDate,
  sortString,
  TableSortData,
  TableSortDirection,
} from "@pimo/pimo-components";
import { CRQRun, CRQRunSuccess, OE, QuestionnaireResponse } from "crq-types";
import { getLatestSuccessfulCRQRun } from "crq-utils";

const SORT_BY_KEY = {
  name: (a, b, direction) => {
    return sortString(a.name ?? "", b.name ?? "", direction);
  },
  contact: (a, b, direction) => {
    return sortString(
      a.questionnaireResponse?.contact ?? "",
      b.questionnaireResponse?.contact ?? "",
      direction
    );
  },
  annualAverageLoss: (a, b, direction) => {
    const { annualAverageLoss: annualAverageLossA } =
      getLatestSuccessfulCRQRun(a) ?? ({} as CRQRun & CRQRunSuccess);

    const { annualAverageLoss: annualAverageLossB } =
      getLatestSuccessfulCRQRun(b) ?? ({} as CRQRun & CRQRunSuccess);

    return sortString(
      annualAverageLossA ?? "",
      annualAverageLossB ?? "",
      direction
    );
  },
  lossEventProbability: (a, b, direction) => {
    const { lossEventProbability: lossEventProbabilityA } =
      getLatestSuccessfulCRQRun(a) ?? ({} as CRQRun & CRQRunSuccess);

    const { lossEventProbability: lossEventProbabilityB } =
      getLatestSuccessfulCRQRun(b) ?? ({} as CRQRun & CRQRunSuccess);

    return sortString(
      lossEventProbabilityA ?? "",
      lossEventProbabilityB ?? "",
      direction
    );
  },
  date: (a, b, direction) => {
    const { date: runDateA } =
      getLatestSuccessfulCRQRun(a) ?? ({} as CRQRun & CRQRunSuccess);

    const { date: runDateB } =
      getLatestSuccessfulCRQRun(b) ?? ({} as CRQRun & CRQRunSuccess);

    return sortByDate(runDateA ?? "", runDateB ?? "", direction);
  },
  questionnaireSubmittedAt: (a, b, direction) => {
    return sortByDate(
      a.questionnaireResponse?.questionnaireSubmittedAt ?? "",
      b.questionnaireResponse?.questionnaireSubmittedAt ?? "",
      direction
    );
  },
} as {
  [key in keyof Partial<OE & CRQRun & CRQRunSuccess & QuestionnaireResponse>]: (
    a: OE,
    b: OE,
    direction: TableSortDirection
  ) => number;
};

export function sortOEs(
  { key, direction }: TableSortData<OE>,
  OEs?: OE[]
): OE[] | undefined {
  return (
    OEs &&
    [...OEs].sort((a, b) => {
      const sortingFn = SORT_BY_KEY[key];

      if (!sortingFn) {
        return 0;
      }

      return sortingFn(a, b, direction);
    })
  );
}
