import dayjs from "dayjs";

import { TableSortDirection } from "../../../types";

export function sortString(
  a: string,
  b: string,
  direction: TableSortDirection
) {
  a = a.toLowerCase();
  b = b.toLowerCase();

  if (a < b) {
    if (direction === "DESC") {
      return 1;
    }
    return -1;
  }
  if (a > b) {
    if (direction === "DESC") {
      return -1;
    }
    return 1;
  }
  return 0;
}

export function sortNumber(
  a: number,
  b: number,
  direction: TableSortDirection
) {
  if (a < b) {
    if (direction === "DESC") {
      return 1;
    }
    return -1;
  }
  if (a > b) {
    if (direction === "DESC") {
      return -1;
    }
    return 1;
  }
  return 0;
}

export function sortByDate(
  a: string | undefined,
  b: string | undefined,
  direction: TableSortDirection
) {
  if (!a) {
    if (direction === "DESC") {
      return 1;
    }
    return -1;
  }
  if (!b) {
    if (direction === "DESC") {
      return -1;
    }
    return 1;
  }

  const dateA = dayjs(a);
  const dateB = dayjs(b);

  if (dateA.isSame(dateB, "day")) {
    return 0;
  }

  if (dateA.isAfter(dateB, "day")) {
    if (direction === "DESC") {
      return -1;
    }
    return 1;
  }

  if (direction === "DESC") {
    return 1;
  }
  return -1;
}
