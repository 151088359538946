import {
  InfoOutlined,
  NavigateBeforeRounded,
  NavigateNextRounded,
} from "@mui/icons-material";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import { DefaultChartsAxisTooltipContent } from "@mui/x-charts";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  Markdown,
  MONTHS,
  MuiLineChartCard,
  type MuiLineChartCardProps,
} from "@pimo/pimo-components";
import type { Currency } from "crq-types";
import { formatDate, formatNumber } from "crq-utils";
import { useState } from "react";

export type CRQLineChartCardProps = {
  currency?: Currency;
  currentYear: number;
  dates: (string | null)[];
  endDate: Date;
  headline?: string;
  infoText?: string;
  startDate: Date;
} & MuiLineChartCardProps;

export type CRQLineChartCardEventMap = {
  "change-time": {
    currentScale: "monthly";
    currentYear: number;
  };
};
export type CRQLineChartCardEvent = keyof CRQLineChartCardEventMap;
export type CRQLineChartCardEventPayload =
  CRQLineChartCardEventMap[CRQLineChartCardEvent];
export const CRQLineChartCard: PimoReactComponent<
  CRQLineChartCardProps,
  CRQLineChartCardEvent,
  CRQLineChartCardEventPayload
> = ({
  currency,
  currentYear,
  dates,
  endDate,
  headline,
  infoText,
  series,
  startDate,
  fireEvent,
  ...props
}) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null
  );

  const endYear = endDate.getFullYear();
  const startYear = startDate.getFullYear();
  const max = Math.max(
    ...series.flatMap((s) => s.data).filter((v) => v != null)
  );

  return (
    <>
      <MuiLineChartCard
        {...props}
        cardProps={{
          rightSlot: infoText ? (
            <IconButton
              onClick={(e) => setPopoverAnchor(e.target as HTMLButtonElement)}
            >
              <InfoOutlined />
            </IconButton>
          ) : undefined,
        }}
        tooltip={{ trigger: "axis" }}
        slots={{
          axisContent: (props) => {
            const verticalHighlightLine = document.querySelector(
              ".MuiChartsAxisHighlight-root"
            );
            verticalHighlightLine?.setAttribute("style", "stroke: #000");

            // for the first "dummy" value don't show the tooltip and vertical hover line
            if (props.dataIndex === 0) {
              verticalHighlightLine?.setAttribute("style", "stroke: #FFF");

              return;
            }

            return (
              <DefaultChartsAxisTooltipContent
                {...props}
                axis={{
                  ...props.axis,
                  valueFormatter: (value) => {
                    if (dates?.[value as number] != null) {
                      return formatDate(dates[value as number]!);
                    }

                    return (
                      MONTHS[props?.axisValue as number]?.substring(0, 3) ??
                      "n/a"
                    );
                  },
                }}
              />
            );
          },
        }}
        series={series.map((serie) => ({
          ...serie,
          valueFormatter: (value: number | null) => {
            if (value !== null) {
              return formatNumber({ number: value });
            }
          },
        }))}
        xAxis={LINE_CHART_X_AXIS()}
        yAxis={LINE_CHART_Y_AXIS({
          currency,
          min: 0,
          max: isFinite(max)
            ? Math.floor(max * 1.3) <= DEFAULT_Y_AXIS_MAX
              ? DEFAULT_Y_AXIS_MAX
              : Math.floor(max * 1.3)
            : undefined,
        })}
      >
        {headline && (
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontSize: "1.9rem",
                fontWeight: "bold",
                color: "black",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              {headline}
            </Typography>
          </Box>
        )}
        {startYear !== endYear && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ minWidth: 120 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
                  <NavigateBeforeRounded
                    sx={{
                      cursor: currentYear === startYear ? "auto" : "pointer",
                      color: currentYear === startYear ? "lightgray" : "black",
                    }}
                    onClick={() => {
                      if (currentYear === startYear) {
                        return;
                      }

                      fireEvent?.("change-time", {
                        currentYear: currentYear - 1,
                        currentScale: "monthly",
                      });
                    }}
                  />
                  <Box>{currentYear}</Box>
                  <NavigateNextRounded
                    sx={{
                      cursor: currentYear === endYear ? "auto" : "pointer",
                      color: currentYear === endYear ? "lightgray" : "black",
                    }}
                    onClick={() => {
                      if (currentYear === endYear) {
                        return;
                      }

                      fireEvent?.("change-time", {
                        currentYear: currentYear + 1,
                        currentScale: "monthly",
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </MuiLineChartCard>

      {infoText && (
        <Popover
          open={!!popoverAnchor}
          anchorEl={popoverAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setPopoverAnchor(null)}
        >
          <Typography component="div" sx={{ maxWidth: "500px", px: 2 }}>
            <Markdown>{infoText}</Markdown>
          </Typography>
        </Popover>
      )}
    </>
  );
};

const LINE_CHART_X_AXIS = () => [
  {
    tickSize: 1,
    tickMaxStep: 1,
    tickMinStep: 1,
    label: "Months",
    data: [-0.5, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], // -0.5 is needed to move the first month to the right
    valueFormatter: (val: number) => MONTHS[val]?.substring(0, 3) ?? "",
  },
];

const DEFAULT_Y_AXIS_MAX = 10;

const LINE_CHART_Y_AXIS = ({
  currency,
  min = 0,
  max = DEFAULT_Y_AXIS_MAX,
}: {
  currency?: Currency;
  min?: number;
  max?: number;
}) => [
  {
    min,
    max,
    tickSize: 1,
    label: [currency, "mn"].filter(Boolean).join(" "),
  },
];
