import {
  Close,
  Menu,
  NotificationImportantOutlined,
  PublishedWithChanges,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Icon,
  IconButton,
  styled,
  Toolbar,
  useTheme,
} from "@mui/material";
import { Markdown } from "@pimo/pimo-app-builder";
import type { FC, PropsWithChildren } from "react";

import type { Notification } from "../../types/notification";
import {
  HeaderActionButton,
  HeaderActionButtonComponent,
} from "../action-button/action-button";
import { ActionSidebar } from "../action-sidebar/action-sidebar";
import {
  HeaderAccountSettings,
  HeaderAccountSettingsProps,
} from "../header-account-settings/header-account-settings";
import { Logo } from "../logo/logo";

export type HeaderProps = PropsWithChildren<{
  /** logo attributes */
  logo?: {
    /** logo alt attribute */
    alt?: string;
    /** logo for screen > 600px */
    big?: string;
    /** logo for screens <600px */
    small?: string;
  };
  /** array of action buttons to display in the header */
  actionButtons?: HeaderActionButton[];
  /** method called to show or hide sidebar on MenuButton click */
  handleSidebarToggle?: () => void;
  /** handler for click on logo */
  handleLogoClick?: () => void;
  hideAccountSettings?: boolean;
  hideMenuButton?: boolean;
  enableLogs?: boolean;
  onLogsShow?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onNotificationRead?: (notification: Notification) => void;
  banner?: Notification;
}> &
  HeaderAccountSettingsProps;

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
  border: 0,
});

/** Component that displays at the top of the page */
export const Header: FC<HeaderProps> = ({
  children,
  actionButtons,
  entries,
  logo = {},
  username,
  groups,
  hideAccountSettings,
  hideMenuButton,
  handleSidebarToggle,
  handleLogoClick,
  enableLogs,
  onLogsShow,
  onNotificationRead,
  banner,
}) => {
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: 0,
        paddingTop: `env(safe-area-inset-top) !important`,
      }}
      data-testid="Header"
      style={{
        backgroundColor: theme.palette.common.white,
      }}
    >
      <StyledToolbar>
        <Button
          onClick={handleLogoClick}
          style={{ alignItems: "center", verticalAlign: "middle" }}
        >
          <Logo
            alt={logo?.alt ?? ""}
            big={logo?.big ?? ""}
            small={logo?.small ?? ""}
          />
        </Button>
        <Box sx={{ flexGrow: 1 }}>
          {!hideMenuButton && (
            <IconButton
              data-testid="header-button-toggleSidebar"
              color="primary"
              aria-label="open drawer"
              edge="start"
              sx={{ ml: 0 }}
              onClick={handleSidebarToggle}
            >
              <Menu
                sx={{
                  backgroundColor: theme.palette.secondary.light,
                  p: 0.5,
                  borderRadius: theme.shape.borderRadius,
                }}
                height={32}
                width={32}
              />
            </IconButton>
          )}
        </Box>
        <Box sx={{ alignItems: "center", display: "flex", gap: 2.5 }}>
          {actionButtons?.map((actionButton, index) => (
            <Box key={index}>
              <HeaderActionButtonComponent
                icon={actionButton.icon}
                text={actionButton.text}
                badgeValue={actionButton.badgeValue}
                onClick={actionButton.onClick}
              />
              {actionButton.actionSidebar && (
                <ActionSidebar
                  items={actionButton.actionSidebar.items}
                  isOpen={actionButton.actionSidebar.isOpen}
                  onClose={actionButton.actionSidebar.onClose}
                  title={actionButton.actionSidebar.title}
                  itemHeaders={actionButton.actionSidebar.itemHeaders}
                />
              )}
            </Box>
          ))}
          {children}
          {enableLogs && (
            <Button
              sx={{
                background: theme.palette.secondary.main,
                color: "#F86200",
                minWidth: 0,
                borderRadius: "8px",
              }}
              onClick={(e) => onLogsShow?.(e)}
            >
              <PublishedWithChanges />
            </Button>
          )}
          {!hideAccountSettings && (
            <HeaderAccountSettings
              entries={entries}
              username={username}
              groups={groups}
            />
          )}
        </Box>
      </StyledToolbar>
      {banner && banner.publishBannerNotification && (
        <Box
          sx={{
            background: "rgba(255, 193, 7, 0.35)",
            color: "#000",
            padding: "12px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "12px",
          }}
        >
          <Icon component={NotificationImportantOutlined} />
          <Box sx={{ flex: "1 1 100%" }}>
            {banner.title && banner.text && (
              <strong>{banner.title}: &nbsp;</strong>
            )}
            {banner.title && !banner.text && banner.title}
            {banner.text && !banner.title && banner.text}
            <Markdown inline>{banner.text}</Markdown>
          </Box>
          <IconButton onClick={() => onNotificationRead?.(banner)}>
            <Icon component={Close} />
          </IconButton>
        </Box>
      )}
    </AppBar>
  );
};
