import { Event } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import {
  OverviewTableCell,
  OverviewTableCellBody,
  OverviewTableCellHeader,
} from "@pimo/pimo-components";
import type { UpdateStatus } from "crq-types";
import React from "react";

import { CRQStatusIndicator } from "../crq-status-indicator";

export interface CheckBoxCellProps {
  date: string;
  updateStatus: UpdateStatus;
  header?: string;
  icon?: string;
  iconSize?: string;
}

export const CheckBoxCell: React.FC<CheckBoxCellProps> = ({
  date,
  header,
  icon,
  iconSize,
  updateStatus,
}) => {
  const theme = useTheme();

  if (!updateStatus) {
    return null;
  }

  return (
    <OverviewTableCell>
      {header && <OverviewTableCellHeader>{header}</OverviewTableCellHeader>}
      <OverviewTableCellBody sx={{ height: "auto" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "1.5em",
            width: "150px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              height: "1.5em",
              lineHeight: "1.7em",
              fontSize: "14px",
            }}
          >
            {icon ? (
              <Box
                component="img"
                src={icon}
                sx={{ pr: 1, width: iconSize, height: iconSize }}
              />
            ) : (
              <Event
                sx={{
                  color: theme.palette.primary.light,
                  pr: 1,
                  width: "2rem",
                }}
              />
            )}
            {date}
          </Box>
          <CRQStatusIndicator
            status={updateStatus}
            styleProps={{
              width: "1.25rem",
              height: "1.25rem",
            }}
          />
        </Box>
      </OverviewTableCellBody>
    </OverviewTableCell>
  );
};
