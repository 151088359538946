import { type App, type Plugin } from "@pimo/pimo-app-builder";
import {
  ChipCell,
  DateCell,
  DerivePimoBaseTableProps,
  generateTableHeaderEntriesWithSorting,
  GridLayout,
  IndexCell,
  NumberCard,
  PimoBaseTable,
  PimoBaseTableEventNames,
  PimoTableEventPayload,
  PimoTableSortEventPayload,
  SortDirection,
  TextCardCell,
} from "@pimo/pimo-components";
import type {
  CRQRun,
  CRQRunSuccess,
  FilterData,
  OE,
  QuestionnaireResponse,
} from "crq-types";
import {
  formatNumber,
  getDraftStatus,
  getLatestSuccessfulCRQRun,
  getStatus,
} from "crq-utils";
import { formatDate } from "crq-utils";
import { generatePath } from "react-router-dom";

import { CRQAppState } from "../app";
import { CheckBoxCell } from "../components/overview/checkbox-cell";
import { ProjectOverviewTitleCard } from "../components/project-overview-title-card/project-overview-title-card";
import { APP_ROUTES } from "../constants";
import { fetchOEOverview, fetchOEs } from "../helpers/fetch-helpers";
import { sortOEs } from "../helpers/sort-oes";
import { getMillionsString } from "../utils";

const DEFAULT_FILTER_DATA = {
  names: [],
  regions: [],
  runs: [],
  search: "",
  status: [],
} satisfies FilterData;

export class OEOverviewPlugin implements Plugin<CRQAppState> {
  onRegister(app: App<CRQAppState>): void {
    const view = app.createView({
      name: "OE Overview",
      layout: new GridLayout(),
    });

    const titleCard = view.addComponent({
      component: ProjectOverviewTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    titleCard.mapState(({ filterDialogData, filterDialogOptions }) => {
      return {
        filterData: filterDialogData ?? DEFAULT_FILTER_DATA,
        names: filterDialogOptions?.names ?? [],
        regions: filterDialogOptions?.regions ?? [],
        title: `OE Overview`,
      };
    });

    titleCard.on("filter:clear", async () => {
      if (!app) {
        return;
      }

      const [OEOverview, OEs] = await Promise.all([
        fetchOEOverview(),
        fetchOEs(),
      ]);

      app.patchAppState({
        filterDialogData: { ...DEFAULT_FILTER_DATA },
        OEs: OEs ?? [],
        OEOverview: OEOverview ?? [],
      });
    });

    titleCard.on("filter:apply", async ({ payload }) => {
      const state: CRQAppState = app.getAppState();

      const oeProjects = await fetchOEs(
        payload,
        state.program?.reportingDate
          ? formatDate(state.program.reportingDate)
          : undefined
      );

      const oeProjectOverview = await fetchOEOverview(
        payload,
        state.program?.reportingDate
          ? formatDate(state.program.reportingDate)
          : undefined
      );

      app.patchAppState({
        filterDialogData: payload,
        OEOverview: oeProjectOverview,
        OEs: oeProjects ?? [],
      });
    });

    const numberOfOEsCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12 / 4,
      },
    });

    numberOfOEsCard.mapState(({ OEOverview }) => {
      return {
        title: "Number of OEs",
        number: OEOverview?.numberOfOEs ?? 0,
      };
    });

    const lastCRQRunCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12 / 4,
      },
    });

    lastCRQRunCard.mapState(({ OEOverview }) => {
      return {
        title: "Last Automated CRQ Run",
        number: OEOverview?.lastCRQRun
          ? formatDate(OEOverview.lastCRQRun)
          : "n/a",
      };
    });

    const percentageOfSuccessfulRunsCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12 / 4,
      },
    });

    percentageOfSuccessfulRunsCard.mapState(({ OEOverview }) => {
      return {
        title: "Percentage of Successful Runs",
        number:
          OEOverview?.percentageOfSuccessfulRuns != null
            ? `${formatNumber({ number: OEOverview.percentageOfSuccessfulRuns })}%`
            : "n/a",
      };
    });

    const numberOfUpToDateQuestionnairesCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12 / 4,
      },
    });

    numberOfUpToDateQuestionnairesCard.mapState(({ OEOverview }) => {
      return {
        title: "# of Up to Date Questionnaire",
        number: `${OEOverview?.numberOfUpToDateQuestionnaires ?? 0}/${
          OEOverview?.numberOfOEs ?? 0
        }`,
      };
    });

    const tableDefinition = [
      { component: IndexCell },
      { component: TextCardCell },
      { component: TextCardCell },
      { component: ChipCell },
      { component: ChipCell },
      { component: DateCell },
      { component: CheckBoxCell },
    ] as const;

    const table = new PimoBaseTable(tableDefinition);

    const tableComponent = view.addComponent<
      DerivePimoBaseTableProps<typeof table>,
      PimoBaseTableEventNames,
      PimoTableEventPayload
    >({
      component: table,
      layoutProps: {
        xs: 12,
      },
    });

    tableComponent.on("sort", (event) => {
      const payload = event?.payload as PimoTableSortEventPayload<keyof OE>;
      const state = app.getAppState();
      let direction: SortDirection = "ASC";

      // Swap direction if key is already being sorted by in ascending order.
      if (
        state.OEOverviewSortData?.key === payload?.sort?.key &&
        state.OEOverviewSortData?.direction === "ASC"
      ) {
        direction = "DESC";
      }

      app.patchAppState({
        OEOverviewSortData: {
          key: payload?.sort?.key,
          direction,
        },
      });
    });

    tableComponent.mapState((state) => ({
      isStickyHeader: true,
      data:
        sortOEs(
          state.OEOverviewSortData || {
            key: "name",
            direction: "ASC",
          },
          state.OEs
        )?.map(
          (
            oe,
            index
          ): DerivePimoBaseTableProps<typeof table>["data"][number] => {
            const { annualAverageLoss, date, lossEventProbability } =
              getLatestSuccessfulCRQRun(oe) ?? ({} as CRQRun & CRQRunSuccess);

            const isOEDraftMode = oe.active === false;

            const status = isOEDraftMode
              ? getDraftStatus(oe.questionnaireResponse ?? null)
              : (getStatus(
                  state.program?.reportingDate ?? null,
                  oe.questionnaireResponse?.isDataUpToDate ?? false
                ) ?? "unknown");

            return {
              rowProps: {
                onClick: () => {
                  if (!oe?.id) {
                    return;
                  }

                  app?.navigate(
                    generatePath(APP_ROUTES.report, { id: String(oe.id) })
                  );
                },
              },
              columnProps: [
                {
                  indexValue: index + 1,
                  cardProps: {
                    sx: {
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "18px",
                    },
                  },
                },
                {
                  body: oe.name + " " + (oe.active === false ? "(Draft)" : ""),
                  bodyTextSize: "small",
                  tooltipShow: true,
                  tooltipTitle:
                    oe.name + " " + (oe.active === false ? "(Draft)" : ""),
                  bodyProps: {
                    sx: {
                      lineHeight: "1.2em",
                      height: "1.2em",
                      overflow: "hidden",
                      display: "-webkit-box",
                      lineBreak: "anywhere",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    },
                  },
                },
                {
                  body: oe.questionnaireResponse?.contact ?? "",
                  bodyTextSize: "small",
                  tooltipShow: true,
                  tooltipTitle: "",
                  bodyProps: {
                    sx: {
                      lineHeight: "1.2em",
                      height: "1.2em",
                      overflow: "hidden",
                      display: "-webkit-box",
                      lineBreak: "anywhere",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    },
                  },
                },
                {
                  body:
                    annualAverageLoss != null
                      ? getMillionsString({
                          currency: oe.questionnaireResponse?.currency,
                          number: +annualAverageLoss,
                        })
                      : "n/a",
                  chipProps: {
                    sx: {
                      borderRadius: "6px",
                      backgroundColor: "#2196F3",
                      color: "white",
                      fontSize: "14px",
                      height: "1.7em",
                      lineHeight: "1.7em",
                      maxWidth: 150,
                      minWidth: 150,
                    },
                  },
                  bodyProps: {
                    sx: {
                      alignItems: "center",
                    },
                  },
                },
                {
                  body:
                    lossEventProbability != null
                      ? getMillionsString({
                          currency: oe.questionnaireResponse?.currency,
                          number: +lossEventProbability,
                        })
                      : "n/a",
                  chipProps: {
                    sx: {
                      borderRadius: "6px",
                      backgroundColor: "#2196F3",
                      color: "white",
                      fontSize: "14px",
                      height: "1.7em",
                      lineHeight: "1.7em",
                      maxWidth: 150,
                      minWidth: 150,
                    },
                  },
                  bodyProps: {
                    sx: {
                      alignItems: "center",
                    },
                  },
                },
                {
                  date: date ? formatDate(date) : "n/a",
                  icon: "calendar-outlined.svg",
                  iconSize: "30px",
                  cardProps: {
                    sx: {
                      fontSize: "14px",
                      lineHeight: "1.7em",
                    },
                  },
                },
                {
                  date: oe.questionnaireResponse?.questionnaireSubmittedAt
                    ? formatDate(
                        oe.questionnaireResponse.questionnaireSubmittedAt
                      )
                    : "n/a",
                  icon: "calendar-outlined.svg",
                  iconSize: "30px",
                  updateStatus: status,
                },
              ],
            };
          }
        ) ?? [],
      tableHeaderEntries: generateTableHeaderEntriesWithSorting<
        OE & CRQRun & CRQRunSuccess & QuestionnaireResponse
      >({
        entries: [
          {
            fieldName: "orderNumber" as keyof OE,
            label: "#",
            isSortable: false,
          },
          {
            fieldName: "name",
            label: "OE",
          },
          {
            fieldName: "contact",
            label: "CRQ Contact",
          },
          {
            fieldName: "annualAverageLoss",
            label: "Average Annual Loss",
          },
          {
            fieldName: "lossEventProbability",
            label: "1-in-20 Years Loss",
          },
          {
            fieldName: "date",
            label: "Last CRQ Run",
          },
          {
            fieldName: "questionnaireSubmittedAt",
            label: "Last Questionnaire Update",
          },
        ],
        tableSortData: state.OEOverviewSortData,
      }),
    }));

    const route = app.createRoute({
      path: APP_ROUTES.overview,
      view,
    });

    route.on("load", async () => {
      if (!app) {
        return;
      }

      const [OEOverview, OEs] = await Promise.all([
        fetchOEOverview(),
        fetchOEs(),
      ]);

      app.patchAppState({
        filterDialogData: undefined,
        OEOverview,
        OEs,
      });
    });
  }
}
