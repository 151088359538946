import { GlobalSetting } from "crq-types";

import { STRAPI_URL } from "../env";

export async function fetchGlobalSetting(): Promise<GlobalSetting | undefined> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff/global-setting`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as GlobalSetting;
  } catch {
    return;
  }
}
export async function updateGlobalSetting(
  type: GlobalSetting["type"]
): Promise<Response | undefined> {
  try {
    return await fetch(`${STRAPI_URL}/api/bff/global-setting`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ type }),
    });
  } catch {
    return;
  }
}
