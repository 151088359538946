import { CheckCircleRounded } from "@mui/icons-material";
import { SxProps } from "@mui/system";
import type { UpdateStatus } from "crq-types";
import type { FC } from "react";

import { STATUS_COLORS } from "../constants";

export type CRQStatusIndicatorProps = {
  status: UpdateStatus;
  styleProps?: SxProps;
};

export const CRQStatusIndicator: FC<CRQStatusIndicatorProps> = ({
  status,
  styleProps = {},
}) => {
  return (
    <CheckCircleRounded
      sx={{
        color: STATUS_COLORS[status],
        backgroundColor: "white",
        borderRadius: 100,
        ...styleProps,
      }}
    />
  );
};
