import { Box, Button, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

export interface TaskItemData {
  id?: string;
  taskId?: string;
  taskTitle?: string;
  type?: string;
  dueDate?: string;
  status?: string;
}

export interface TaskItemProps {
  task: TaskItemData;
  onTaskClick: () => void;
  taskTitle: string;
  actionLabel?: string;
}

export const TaskItem: PimoReactComponent<TaskItemProps> = ({
  task,
  onTaskClick,
  taskTitle,
  actionLabel = "Report",
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr 1fr",
        gap: 2,
        alignItems: "center",
        width: "100%",
        p: 2,
        color: "text.primary",
      }}
    >
      <Typography variant="body1">{taskTitle}</Typography>
      <Typography variant="body2">
        {task?.dueDate ? new Date(task.dueDate).toLocaleDateString() : ""}
      </Typography>
      <Box>
        <Button
          variant="contained"
          size="small"
          onClick={onTaskClick}
          sx={{
            bgcolor: "primary.main",
            color: "common.white",
            borderRadius: 2,
            "&:hover": {
              bgcolor: "primary.dark",
            },
            minWidth: "100px",
          }}
        >
          {actionLabel}
        </Button>
      </Box>
    </Box>
  );
};
