import { Close, PublishedWithChanges, Sync } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useEffect, useRef } from "react";

export type Log = {
  collectionID: number;
  collectionName: string;
  initiator: string;
  objectName: string;
  newValue?: string;
  parentObjectName?: string;
  previousValue?: string;
  updatedFieldName: string;
  action?: "create" | "delete" | "edit";
  createdAt: string;
};

export type LogDrawerProps = {
  logs?: Log[];
  open: boolean;
  setOpen: (open: boolean) => void;
  onLoadMore?: () => void;
};

export const LogDrawer: PimoReactComponent<LogDrawerProps> = ({
  logs,
  open,
  setOpen,
  onLoadMore,
}) => {
  const theme = useTheme();
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && onLoadMore) {
          onLoadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [onLoadMore]);

  return (
    <Drawer
      anchor="right"
      hideBackdrop
      ModalProps={{ keepMounted: false }}
      onClose={() => setOpen(false)}
      open={open}
      variant="temporary"
      sx={{
        "& .MuiDrawer-paper": {
          border: "0px solid",
          borderRadius: "12px",
          boxSizing: "border-box",
          boxShadow: "none",
          maxHeight: "calc(100% - 90px)",
          mt: "75px",
        },
      }}
    >
      <Box
        sx={{
          boxShadow: "none",
          [theme.breakpoints.down("sm")]: { display: "none" },
          "@media print": { display: "none" },
        }}
      >
        <Box
          sx={{
            background: theme.palette.background.default,
            position: "sticky",
            pt: 2,
            px: 2,
            top: 0,
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignContent: "center",
              }}
            >
              <PublishedWithChanges sx={{ color: "#F86200" }} />
              <Typography sx={{ fontWeight: 500, fontSize: "1rem" }}>
                Change Log
              </Typography>
            </Box>
            <Button
              sx={{ color: "grey", minWidth: 0 }}
              onClick={() => setOpen(false)}
            >
              <Close />
            </Button>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              borderWidth: 0,
              height: "1px",
              mt: 1,
            }}
          />
        </Box>

        <List sx={{ overflowY: "auto" }}>
          {logs?.map((entry, index) => (
            <ListItem
              key={`${entry.createdAt}-${index}`}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                gap: 2,
                width: 650,
              }}
            >
              <Accordion sx={{ boxShadow: "none" }}>
                <AccordionSummary>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Sync sx={{ color: "#2196F3" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {(!entry.action || entry.action === "edit") && (
                        <Box sx={{ fontWeight: 500 }}>
                          {entry.initiator}
                          <Typography display="inline">
                            {" "}
                            updated{" "}
                            {entry.objectName ? `${entry.objectName} -` : ""}
                            {entry.parentObjectName
                              ? `${entry.parentObjectName} -`
                              : ""}
                          </Typography>
                          <Typography
                            display="inline"
                            sx={{ color: "#2196F3" }}
                          >
                            {" "}
                            Field: {entry.updatedFieldName}
                          </Typography>
                        </Box>
                      )}
                      {entry.action === "create" && (
                        <Box sx={{ fontWeight: 500 }}>
                          {entry.initiator}
                          <Typography display="inline">
                            {" "}
                            added{" "}
                            {entry.objectName
                              ? `${entry.objectName}`
                              : ""} -{" "}
                            {entry.parentObjectName
                              ? `${entry.parentObjectName}`
                              : ""}
                          </Typography>
                        </Box>
                      )}
                      {entry.action === "delete" && (
                        <Box sx={{ fontWeight: 500 }}>
                          {entry.initiator}
                          <Typography display="inline">
                            {" "}
                            deleted{" "}
                            {entry.objectName
                              ? `${entry.objectName}`
                              : ""} -{" "}
                            {entry.parentObjectName
                              ? `${entry.parentObjectName}`
                              : ""}
                          </Typography>
                        </Box>
                      )}
                      <Typography
                        sx={{
                          width: "100%",
                          textAlign: "left",
                          fontSize: "0.7rem",
                          color: "GrayText",
                        }}
                      >
                        {new Date(entry.createdAt).toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                {(entry.previousValue || entry.newValue) && (
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-evenly",
                        gap: 1,
                        p: 1,
                        px: 4,
                      }}
                    >
                      {[entry.previousValue, entry.newValue].map(
                        (value, index) => (
                          <Box
                            key={`${value}-${index}`}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "50%",
                            }}
                          >
                            <Typography sx={{ fontWeight: 500 }}>
                              {index === 0 ? "Previous" : "New"} Value
                            </Typography>
                            <Typography
                              sx={{
                                border: "1px solid",
                                borderColor: grey[100],
                                borderRadius: "12px",
                                p: 1,
                                overflow: "hidden",
                                wordBreak: "break-word",
                              }}
                            >
                              {value}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  </AccordionDetails>
                )}
              </Accordion>
            </ListItem>
          ))}
          <div ref={loadMoreRef} style={{ height: "20px" }} />
        </List>
      </Box>
    </Drawer>
  );
};
