import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import React from "react";

export interface DropdownFilterProps {
  options: string[];
  value: string[];
  label: string;
  triggerIdentifier: string;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
}

export const DropdownFilter: PimoReactComponent<DropdownFilterProps> = ({
  options,
  value,
  label,
  handleChange,
  triggerIdentifier,
}) => {
  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        sx={{ width: 270 }}
        value={value ?? []}
        multiple
        renderValue={() => (value ?? []).map((id) => id).join(", ")}
        input={<OutlinedInput label={label} />}
        MenuProps={{ sx: { textOverflow: "ellipsis" } }}
      >
        {options.map((option, index) => (
          <MenuItem key={index}>
            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  minWidth: 210,
                },
                ".MuiFormControlLabel-root": {
                  minWidth: 210,
                },
              }}
              checked={value.includes(option)}
              labelPlacement="end"
              label={option}
              control={
                <Checkbox
                  onChange={(e) => handleChange(e, triggerIdentifier)}
                  name={option}
                  checked={value.includes(option)}
                  sx={{
                    color: "lightgrey",
                  }}
                />
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
