import { SxProps } from "@mui/material";

import { TableSortData } from "../../../types";

export function generateTableHeaderEntriesWithSorting<TableData>({
  entries,
  tableSortData,
}: {
  entries: {
    fieldName: keyof TableData;
    label: string;
    isSortable?: boolean;
    sx?: SxProps;
  }[];
  tableSortData?: TableSortData<TableData>;
}) {
  return entries.map(({ fieldName, label, sx, isSortable = true }) => ({
    fieldName,
    label,
    sx,
    isSortable,
    sortDirection:
      tableSortData?.key === fieldName ? tableSortData.direction : null,
  }));
}
