import { Button as MUIButton } from "@mui/material";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import React from "react";

export interface PimoButtonProps extends MuiButtonProps {
  borderColor?: string;
  text: string;
  icon?: React.ReactNode;
  customStyles?: React.CSSProperties;
}

export const PimoButton: React.FC<PimoButtonProps> = ({
  borderColor = "#003781",
  text,
  icon,
  customStyles = {},
  ...props
}) => {
  return (
    <MUIButton
      startIcon={icon}
      style={{
        border: `2px solid ${borderColor}`,
        borderRadius: "7px",
        height: "43px",
        padding: "0 16px",
        ...customStyles,
      }}
      {...props}
    >
      {text}
    </MUIButton>
  );
};

export default PimoButton;
