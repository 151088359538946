import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, IconButton, styled, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import type { NotificationItem, TaskItemData } from "./action-sidebar-items";
import { NotificationContent, TaskItem } from "./action-sidebar-items";

export interface ActionSidebarItem {
  id: string | number;
  onClick?: () => void;
  notification?: NotificationItem;
  task?: TaskItemData;
  content?: React.ReactNode;
}

export interface ActionSidebarProps {
  items: ActionSidebarItem[];
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  itemHeaders?: {
    headers: string[];
    gridTemplateColumns?: string;
  };
  onNotificationClick?: (notificationId: string | number) => Promise<void>;
}

const Backdrop = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1200,
  transition: "opacity 0.2s ease",
  opacity: 0,
  "&.open": {
    opacity: 1,
  },
});

const DrawerContainer = styled(Box)(({ theme }) => ({
  width: 650,
  height: "calc(100vh - 64px)",
  top: "62px",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "24px 0 0 24px",
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRight: "none",
  boxShadow: theme.shadows[4],
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  right: 0,
  zIndex: 1300,
  transform: "translateX(100%)",
  transition: "transform 0.2s ease",
  overflow: "hidden",
  "&.open": {
    transform: "translateX(0)",
  },
}));

const MenuHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderTopLeftRadius: "6px",
}));

const MenuContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: "auto",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderBottomLeftRadius: "6px",
}));

export const ActionSidebar: PimoReactComponent<ActionSidebarProps> = ({
  items,
  isOpen,
  onClose,
  title,
  itemHeaders,
  onNotificationClick,
}) => (
  <>
    <Backdrop
      onClick={onClose}
      className={isOpen ? "open" : ""}
      sx={{ display: isOpen ? "block" : "none" }}
    />
    <DrawerContainer className={isOpen ? "open" : ""}>
      <MenuHeader>
        <Typography
          variant="h6"
          sx={{ fontWeight: 500, color: "text.primary" }}
        >
          {title}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </MenuHeader>
      <Divider sx={{ backgroundColor: "secondary.main" }} />
      <MenuContent>
        {itemHeaders?.headers && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns:
                itemHeaders?.gridTemplateColumns || "2fr 1fr 1fr",
              gap: 2,
              alignItems: "center",
              width: "100%",
              p: 2,
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            {itemHeaders.headers.map((header) => (
              <Typography
                key={header}
                variant="subtitle2"
                sx={{ fontWeight: 600, color: "text.primary" }}
              >
                {header}
              </Typography>
            ))}
          </Box>
        )}
        {items.map((item) => (
          <Box
            key={item.id}
            sx={{
              cursor: item.notification || item.task ? "pointer" : "default",
              "&:hover":
                item.notification || item.task
                  ? {
                      backgroundColor: "action.hover",
                    }
                  : {},
            }}
          >
            {item.notification ? (
              <NotificationContent
                notification={item.notification}
                onClick={async () => {
                  if (item.notification) {
                    await onNotificationClick?.(item.notification.id);
                  }
                  item.onClick?.();
                  onClose();
                }}
              />
            ) : item.task ? (
              <TaskItem
                task={item.task}
                onTaskClick={() => {
                  item.onClick?.();
                  onClose();
                }}
                taskTitle={item.task.taskTitle ?? ""}
                actionLabel="Report"
              />
            ) : (
              item.content
            )}
          </Box>
        ))}
      </MenuContent>
    </DrawerContainer>
  </>
);
