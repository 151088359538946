import {
  DeleteOutlined,
  EditOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { Markdown } from "@pimo/pimo-components";
import type { FilterData, ManageOEEventPayload, OE, Region } from "crq-types";
import { FormFieldErrors, validateFormData } from "crq-utils";
import { useState } from "react";

import { ManageOETitleCard } from "../manage-oe-title-card/manage-oe-title-card";

export type OEManagerProps = {
  oeRegions: Region[];
  oes: OE[];
  filterData: FilterData;
  title: string;
  regions: string[];
  names: string[];
};

type OEManagerEventName =
  | "oe-created"
  | "oe-updated"
  | "oe-deleted"
  | "filter:apply"
  | "filter:clear";

const OE_ID_INFO_TEXT = `
Please use the OE ID you find under this [link](https://allianzms.sharepoint.com/sites/DE1890-connect-azse-group-technology/SitePages/OE%20Chief%20Information%20Security%20Officers.aspx).
`;

export const ManageOEsSettingsPage: PimoReactComponent<
  OEManagerProps,
  OEManagerEventName,
  ManageOEEventPayload | FilterData
> = ({ oeRegions, oes, fireEvent, filterData, title, regions, names }) => {
  const [openForm, setOpenForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInfoAnchor, setOpenInfoAnchor] = useState<HTMLButtonElement>();

  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FormFieldErrors>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [formData, setFormData] = useState<ManageOEEventPayload>({
    name: "",
    cimid: "",
    hideFromGroupDashboard: true,
    region: undefined,
    active: false,
  });

  const handleOpenForm = () => {
    setFormData({
      name: "",
      cimid: "",
      hideFromGroupDashboard: true,
      region: null,
      active: false,
    });
    setHasValidationErrors(true);
    setValidationErrors({});
    setOpenForm(true);
  };

  const handleEdit = (oe: OE) => {
    if (!oe.id) {
      return;
    }

    setFormData({
      id: oe.id,
      name: oe.name ?? "",
      cimid: oe.cimid ?? "",
      hideFromGroupDashboard:
        oe.active === false ? true : (oe.hideFromGroupDashboard ?? false),
      region: oe.region?.id ?? null,
      active: oe.active !== false,
    });

    if (oe.questionnaireResponse) {
      const errors = validateFormData(oe.questionnaireResponse);
      const hasErrors = Object.keys(errors).length > 0;
      setHasValidationErrors(hasErrors);
      setValidationErrors(errors);
    } else {
      setHasValidationErrors(false);
      setValidationErrors({});
    }

    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setHasValidationErrors(false);
    setValidationErrors({});
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    if (formData.id != null) {
      fireEvent?.("oe-updated", { ...formData });
    } else {
      fireEvent?.("oe-created", {
        ...formData,
      });
    }
    handleCloseForm();
  };

  const handleDelete = (oe: OE) => {
    setOpenDelete(true);
    setFormData({
      id: oe.id,
      name: oe.name ?? "",
      cimid: oe.cimid ?? "",
      hideFromGroupDashboard: oe.hideFromGroupDashboard ?? false,
      region: oe.region?.id ?? null,
    });
  };

  const handleConfirmDelete = () => {
    if (formData.id !== null) {
      fireEvent?.("oe-deleted", formData);
    }
    setOpenDelete(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const errorMessages = Object.values(validationErrors).map(
    (error) => (error as { message: string }).message
  );

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        boxShadow: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          padding: "15px 0",
        }}
      >
        <ManageOETitleCard
          title={title}
          filterData={filterData}
          onButtonClick={handleOpenForm}
          regions={regions}
          names={names}
          fireEvent={fireEvent}
        />
      </Stack>

      {oes.length === 0 && (
        <Box sx={{ display: "grid", placeItems: "center", height: "100%" }}>
          <Typography
            sx={{
              color: "#979797",
              fontSize: "30px",
              fontWeight: 500,
              display: "block",
              margin: "auto",
              marginTop: "150px",
              marginBottom: "150px",
            }}
          >
            You have not yet created any OEs
          </Typography>
        </Box>
      )}

      {oes.length > 0 && (
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "700px",
            marginLeft: "24px",
          }}
        >
          <Table>
            <TableBody>
              {oes.map((oe, index) => (
                <TableRow key={oe.id}>
                  <TableCell sx={{ width: "10%" }}>{index + 1}</TableCell>
                  <TableCell sx={{ width: "30%" }}>
                    <strong>
                      {oe.name} {oe.active === false ? "(Draft)" : ""}
                    </strong>
                  </TableCell>
                  <TableCell align="right" sx={{ width: "20%" }}>
                    <Chip
                      label={
                        oe.active == null || oe.active ? "Active" : "Not Active"
                      }
                      sx={{
                        backgroundColor:
                          oe.active == null || oe.active
                            ? "rgba(0, 200, 83, 0.2)"
                            : "#E0E0E0",
                        fontWeight: 500,
                      }}
                    />
                  </TableCell>
                  <TableCell align="right" sx={{ width: "20%" }}>
                    <Chip
                      label={
                        oe.hideFromGroupDashboard || oe.active === false
                          ? "Not Included in the dashboard"
                          : "Included in the dashboard"
                      }
                      sx={{
                        backgroundColor:
                          oe.hideFromGroupDashboard || oe.active === false
                            ? "#E0E0E0"
                            : "rgba(0, 200, 83, 0.2)",
                        fontWeight: 500,
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleEdit(oe)}
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(oe)}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        fullWidth
        maxWidth="md"
        sx={{ margin: 8 }}
        PaperProps={{ sx: { padding: 2 } }}
      >
        <DialogTitle
          sx={{
            color: "#000000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {formData.id != null ? "Edit OE" : "New OE"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <TextField
              label="OE Name*"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
              <TextField
                label="OE ID*"
                value={formData.cimid}
                onChange={(e) =>
                  setFormData({ ...formData, cimid: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  onClick={({ target }) =>
                    setOpenInfoAnchor(target as HTMLButtonElement)
                  }
                >
                  <InfoOutlined />
                </IconButton>
                <Popover
                  open={!!openInfoAnchor}
                  anchorEl={openInfoAnchor}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  onClose={() => setOpenInfoAnchor(undefined)}
                >
                  <Box sx={{ py: 1, px: 2 }}>
                    <Markdown>{OE_ID_INFO_TEXT}</Markdown>
                  </Box>
                </Popover>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>OE Region</InputLabel>
              <Select
                MenuProps={{ sx: { textOverflow: "ellipsis" } }}
                label="OE Region"
                value={formData.region ?? ""}
                onChange={(e) => {
                  if (!e.target?.value) {
                    setFormData({ ...formData, region: null });
                    return;
                  }

                  setFormData({ ...formData, region: +e.target.value });
                }}
              >
                <MenuItem value="">-</MenuItem>
                {oeRegions.map(({ id, regionName }) => {
                  return (
                    <MenuItem key={id} value={id}>
                      {regionName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box sx={{ mt: 4 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Switch
                checked={!formData.hideFromGroupDashboard}
                onChange={(e) => {
                  const hideFromGroupDashboard = !e.target.checked;
                  setFormData({
                    ...formData,
                    hideFromGroupDashboard,
                  });
                }}
                disabled={!formData.active}
              />
              <Typography>OE part of Dashboard</Typography>
            </Stack>
          </Box>
          <Box sx={{ mt: 4 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Switch
                checked={formData.active}
                onChange={(e) => {
                  const isActive = e.target.checked;
                  setFormData({
                    ...formData,
                    active: isActive,
                    hideFromGroupDashboard: !isActive
                      ? true
                      : formData.hideFromGroupDashboard,
                  });
                }}
                disabled={hasValidationErrors}
              />
              <Typography>Activate OE</Typography>
              {hasValidationErrors && formData.id != null && (
                <>
                  <IconButton
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <InfoOutlined />
                  </IconButton>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Box sx={{ p: 2, maxWidth: 300 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Validation Errors:
                      </Typography>
                      {errorMessages.map((msg, index) => (
                        <Typography key={index} variant="body2">
                          - {msg}
                        </Typography>
                      ))}
                    </Box>
                  </Popover>
                </>
              )}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", paddingRight: 2 }}>
          <Button onClick={handleCloseForm}>CLOSE</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            disabled={!formData.name || !formData.cimid}
            sx={{ ml: 2 }}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this OE?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
