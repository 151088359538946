import type { App, Plugin } from "@pimo/pimo-app-builder";
import {
  BarChartCard,
  GridLayout,
  InformationCard,
  SectionHeader,
} from "@pimo/pimo-components";
import {
  GroupDashboardFilterData,
  KOVRR_RECOMMENDED_ACTION_MAP,
} from "crq-types";
import React from "react";
import ReactDOMServer from "react-dom/server";

import { CRQAppState } from "../app";
import { GroupDashboardTitleCard } from "../components/group-dashboard-title-card/group-dashboard-title-card";
import { InfoBox } from "../components/info-box";
import { APP_ROUTES } from "../constants";
import { fetchGroupDashboard } from "../helpers/fetch-helpers";
import { fetchOERegions } from "../helpers/fetch-region";

const DEFAULT_FILTER_DATA: GroupDashboardFilterData = {
  regions: [],
};

export class GroupDashboardPlugin implements Plugin<CRQAppState> {
  onRegister(app: App<CRQAppState>): void {
    const view = app.createView({
      name: "OE Overview",
      layout: new GridLayout(),
    });

    const titleCard = view.addComponent({
      component: GroupDashboardTitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    titleCard.mapState(({ filterDialogData, regions }) => {
      return {
        title: `CRQ Group Dashboard (based on the latest CRQ run)`,
        filterData: filterDialogData ?? DEFAULT_FILTER_DATA,
        regions: regions ? regions.map((region) => region.regionName) : [],
      };
    });

    titleCard.on("filter:apply", async ({ payload }) => {
      if (!app) {
        return;
      }

      const groupDashboard = await fetchGroupDashboard(payload);

      app.patchAppState({
        groupDashboard,
        filterDialogData: {
          regions: payload?.regions ?? [],
          runs: [],
          names: [],
          status: [],
          search: "",
        },
      });
    });

    titleCard.on("filter:clear", async () => {
      if (!app) {
        return;
      }
      const groupDashboard = await fetchGroupDashboard();

      app.patchAppState({
        filterDialogData: undefined,
        groupDashboard,
      });
    });

    const distributionOfRiskFiguresInformationSubtitle = view.addComponent({
      component: SectionHeader,
      layoutProps: { xs: 12 },
    });
    distributionOfRiskFiguresInformationSubtitle.mapState((state) => {
      const INFO_BOX_DISTRIBUTION_OF_RISK_FIGURES = `
These graphs provide an overview of the Cyber Risk Quantification (CRQ) results across all OEs. Based on
the latest Kovrr model run, the distribution of the following key risk figures is shown:

- Average Annual Loss
- 1-in-20 Years Loss (95%-quantile)
- 1-in-100 Years Loss (99%-quantile)

The graphs dynamically adjust according to the selected filter for the OE cluster
`;
      return {
        title: `Distribution of risk figures across OEs (in total ${state.groupDashboard?.numberOfOEs || 0} OEs)`,
        sx: {
          width: "100%",
        },
        infoPointText: INFO_BOX_DISTRIBUTION_OF_RISK_FIGURES,
      };
    });

    const distributionAvgAnnualLoss = view.addComponent({
      component: BarChartCard,
      layoutProps: {
        xs: 12,
        md: 4,
      },
    });
    distributionAvgAnnualLoss.mapState((state) => {
      const riskFigures = state.groupDashboard?.riskFigures?.annualAverageLoss;

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        showLegend: false,
        title: "Distribution Avg. Annual Loss across OEs (Mn €)",
        categories: [
          "<1",
          "1-5",
          "5-10",
          "10-20",
          "20-30",
          "30-50",
          "50-100",
          ">100",
        ],
        series: Object.values(riskFigures?.values ?? {}),
        seriesLabel: "Distribution Avg. Annual Loss",
        currency: "",
        yAxisMin: 0,
        forceIntegerYAxis: true,
        xAxisLabel: "Average Annual Loss",
        yAxisLabel: "Number of OEs",
        customToolbox: ({ dataPointIndex }) => {
          if (!riskFigures || dataPointIndex === undefined) {
            return "";
          }

          const rangeKeys = Object.keys(riskFigures.values);
          const rangeKey = rangeKeys[dataPointIndex];
          const oeNames =
            riskFigures.oeNames[rangeKey as keyof typeof riskFigures.values] ||
            [];

          if (oeNames.length === 0) {
            return "";
          }

          const infoBoxHtml = ReactDOMServer.renderToStaticMarkup(
            React.createElement(InfoBox, { data: oeNames })
          );

          return infoBoxHtml;
        },
      };
    });

    const distributionOn1To20yearLoss = view.addComponent({
      component: BarChartCard,
      layoutProps: {
        xs: 12,
        md: 4,
      },
    });

    distributionOn1To20yearLoss.mapState((state) => {
      const riskFigures =
        state.groupDashboard?.riskFigures?.lossEventProbability;

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "Distribution 1-in-20 years Loss across OEs (Mn €)",
        categories: [
          "<1",
          "1-5",
          "5-10",
          "10-20",
          "20-30",
          "30-50",
          "50-100",
          ">100",
        ],
        showLegend: false,
        series: riskFigures ? Object.values(riskFigures.values) : [],
        seriesLabel: "Distribution 1-in-20 Loss",
        currency: "",
        yAxisMin: 0,
        forceIntegerYAxis: true,
        xAxisLabel: "1-in-20 years loss",
        yAxisLabel: "Number of OEs",
        customToolbox: ({ dataPointIndex }) => {
          if (!riskFigures || dataPointIndex === undefined) {
            return "";
          }

          const rangeKeys = Object.keys(riskFigures.values);
          const rangeKey = rangeKeys[dataPointIndex];
          const oeNames =
            riskFigures.oeNames[rangeKey as keyof typeof riskFigures.values] ||
            [];

          if (oeNames.length === 0) {
            return "";
          }

          const infoBoxHtml = ReactDOMServer.renderToStaticMarkup(
            React.createElement(InfoBox, { data: oeNames })
          );

          return infoBoxHtml;
        },
      };
    });

    const distribution1to100YearLoss = view.addComponent({
      component: BarChartCard,
      layoutProps: {
        xs: 12,
        md: 4,
      },
    });

    distribution1to100YearLoss.mapState((state) => {
      const riskFigures = state.groupDashboard?.riskFigures?.highExposureLoss;

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "Distribution 1-in-100 years Loss across OEs (Mn €)",
        categories: [
          "<1",
          "1-5",
          "5-10",
          "10-20",
          "20-30",
          "30-50",
          "50-100",
          ">100",
        ],
        showLegend: false,
        series: riskFigures ? Object.values(riskFigures.values) : [],
        seriesLabel: "Distribution 1-in-100 years Loss",
        currency: "",
        yAxisMin: 0,
        forceIntegerYAxis: true,
        xAxisLabel: "1-in-100 years loss",
        yAxisLabel: "Number of OEs",
        customToolbox: ({ dataPointIndex }) => {
          if (!riskFigures || dataPointIndex === undefined) {
            return "";
          }

          const rangeKeys = Object.keys(riskFigures.values);
          const rangeKey = rangeKeys[dataPointIndex];
          const oeNames =
            riskFigures.oeNames[rangeKey as keyof typeof riskFigures.values] ||
            [];

          if (oeNames.length === 0) {
            return "";
          }

          const infoBoxHtml = ReactDOMServer.renderToStaticMarkup(
            React.createElement(InfoBox, { data: oeNames })
          );

          return infoBoxHtml;
        },
      };
    });

    const rankingTopRecommendedActionsInformationSubtitle = view.addComponent({
      component: SectionHeader,
      layoutProps: { xs: 12 },
    });
    rankingTopRecommendedActionsInformationSubtitle.mapState((state) => {
      const INFO_BOX_MITIGATION_ACTIONS = `
This overview provides an aggregated ranking of top recommended mitigation actions across all OEs.

Recall that each OE report includes the top mitigation actions recommended to reduce the identified risks. The
OE report shows the Top 3 recommended CIS controls with the highest risk reduction in terms of the average
annual loss.

Now, for each CIS control, the number of OEs having the CIS control within their Top 3 recommended actions
is calculated. The ranking shows the resulting Top 5 CIS controls based on the number of OEs in descending
order.

The ranking dynamically adjusts according to the selected filter for the OE cluster.
`;

      return {
        title: `Ranking of Top Recommended Actions out of ${state.groupDashboard?.numberOfOEs || 0} OEs`,
        sx: {
          width: "100%",
        },
        infoPointText: INFO_BOX_MITIGATION_ACTIONS,
      };
    });

    const progressCard = view.addComponent({
      component: InformationCard,
      layoutProps: {
        xs: 12,
      },
    });
    progressCard.mapState((state) => {
      const groupDashboard = state.groupDashboard?.topRecommendedActions;

      const progressBars =
        groupDashboard?.map((action) => {
          const controlInfo =
            KOVRR_RECOMMENDED_ACTION_MAP[
              action.control as keyof typeof KOVRR_RECOMMENDED_ACTION_MAP
            ] || {};
          return {
            title: controlInfo.abbr,
            subTitle: controlInfo.name || "Unknown Control",
            progress: Math.min(action.count, 100),
            actualValue: `${action.count} OEs`,
            textWeight: 500,
          };
        }) || [];

      return {
        title: `CIS Controls`,
        progressBars,
      };
    });

    const route = app.createRoute({
      path: APP_ROUTES.groupDashboard,
      view,
    });

    route.on("load", async () => {
      if (!app) {
        return;
      }

      const [groupDashboard, regions] = await Promise.all([
        fetchGroupDashboard(),
        fetchOERegions(),
      ]);

      app.patchAppState({
        groupDashboard,
        filterDialogData: undefined,
        regions: regions ?? [],
      });
    });
  }
}
