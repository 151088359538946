import { Box, BoxProps, CardProps } from "@mui/material";
import { FC } from "react";

import {
  OverviewTableCell,
  OverviewTableCellBody,
  OverviewTableCellHeader,
} from "../overview-table-cell";

export interface ChipCellProps {
  /** cell header */
  header?: string;
  /** cell body */
  body: string;
  /** Additional cell styling */
  cardProps?: CardProps;
  /** Additional header styling */
  headerProps?: BoxProps;
  /** Additional body styling */
  bodyProps?: BoxProps;
  /** chip props */
  chipProps?: BoxProps;
  //isHidden not used in here used to hide sells in the pimo table
  isHidden?: boolean;
}

/** Chip Card with title and body for the cells that need to display a header with a chip beneath it */
export const ChipCell: FC<ChipCellProps> = ({
  header,
  body,
  cardProps = {},
  chipProps = {},
  bodyProps = {},
  headerProps = {},
}) => {
  return (
    <>
      <OverviewTableCell {...cardProps} sx={{ ...cardProps.sx }}>
        {header && (
          <OverviewTableCellHeader {...headerProps} sx={{ ...headerProps.sx }}>
            {header}
          </OverviewTableCellHeader>
        )}
        <OverviewTableCellBody
          sx={{
            display: "flex",
            justifyContent: "left",
            height: "1.5em",
            ...bodyProps.sx,
          }}
        >
          <Box
            {...chipProps}
            sx={{
              lineHeight: "1.5em",
              height: "1.5em",
              textAlign: "center",
              py: "auto",
              px: "1em",
              fontWeight: 500,
              ...chipProps.sx,
            }}
          >
            {body}
          </Box>
        </OverviewTableCellBody>
      </OverviewTableCell>
    </>
  );
};

export default ChipCell;
