import type { SingleStrapiResponse } from "@pimo/strapi-types-and-utils";
import type {
  FilterData,
  GroupDashboardFilterData,
  GroupDashboardResponse,
  OE,
  OEOverviewResponse,
  Program,
  QuestionnaireResponse,
} from "crq-types";

import { STRAPI_URL } from "../env";

export async function fetchOE(id: number): Promise<OE | undefined> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-oe/${id}`, {
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as OE;
  } catch {
    return;
  }
}

function buildURLParameters(
  payload: FilterData | undefined,
  reportingDate: string | undefined
) {
  const params: string[] = [];

  if (payload?.search) {
    params.push(`search=${encodeURIComponent(payload.search)}`);
  }
  if (payload?.names?.length) {
    params.push(`names=${encodeURIComponent(payload.names.join(","))}`);
  }
  if (payload?.regions?.length) {
    params.push(`regions=${encodeURIComponent(payload.regions.join(","))}`);
  }
  if (payload?.runs?.length) {
    params.push(`runs=${encodeURIComponent(payload.runs.join(","))}`);
  }
  if (payload?.status?.length) {
    params.push(`status=${encodeURIComponent(payload.status.join(","))}`);
  }
  if (reportingDate) {
    params.push(`date=${encodeURIComponent(reportingDate)}`);
  }

  return params;
}

function buildURLParametersGroupDashboard(
  payload: GroupDashboardFilterData | undefined
) {
  const params: string[] = [];

  if (payload?.regions?.length) {
    params.push(`regions=${encodeURIComponent(payload.regions.join(","))}`);
  }
  return params;
}

export async function fetchOEs(
  payload?: FilterData,
  reportingDate?: string
): Promise<OE[]> {
  try {
    const params = buildURLParameters(payload, reportingDate);

    const response = await fetch(
      `${STRAPI_URL}/api/bff-oe?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as OE[];
  } catch {
    return [];
  }
}
export async function fetchQuestionnaires(): Promise<QuestionnaireResponse[]> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-questionnaire`, {
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as QuestionnaireResponse[];
  } catch {
    return [];
  }
}

export async function updateProgramReportingDate(reportingDate: string) {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-program`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: { reportingDate },
      }),
    });

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
    return (await response.json()) as SingleStrapiResponse<Program>;
  } catch {
    return;
  }
}
export async function resetProgramReportingDate() {
  try {
    const response = await fetch(
      `${STRAPI_URL}/api/bff-program-reset-reporting-date`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }
    return (await response.json()) as SingleStrapiResponse<Program>;
  } catch {
    return;
  }
}

export async function fetchQuestionnaireForOE(
  id: number
): Promise<QuestionnaireResponse | undefined> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-questionnaire/${id}`, {
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(
        `fetchQuestionnaireForOE failed with status ${response.status}.`
      );
    }

    return (await response.json()) as QuestionnaireResponse;
  } catch {
    return undefined;
  }
}

export async function saveQuestionnaireForOE(
  id: number,
  questionnaire: QuestionnaireResponse
): Promise<undefined> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-questionnaire/${id}`, {
      body: JSON.stringify(questionnaire),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
    });

    if (!response.ok) {
      throw new Error(
        `saveQuestionnaireForOE failed with status ${response.status}.`
      );
    }
  } catch {
    return undefined;
  }
}

export async function fetchOEOverview(
  payload?: FilterData,
  reportingDate?: string
): Promise<OEOverviewResponse> {
  try {
    const params = buildURLParameters(payload, reportingDate);

    const response = await fetch(
      `${STRAPI_URL}/api/bff-oe-overview?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error(`fetchOEOverview failed with status ${response.status}.`);
    }

    return (await response.json()) as OEOverviewResponse;
  } catch {
    return {
      lastCRQRun: "n/a",
      numberOfOEs: 0,
      numberOfUpToDateQuestionnaires: 0,
      percentageOfSuccessfulRuns: 0,
      regions: [],
    };
  }
}
export async function fetchGroupDashboard(
  payload?: GroupDashboardFilterData
): Promise<GroupDashboardResponse> {
  try {
    const params = buildURLParametersGroupDashboard(payload);

    const response = await fetch(
      `${STRAPI_URL}/api/bff-group-dashboard?${params.join("&")}`,
      {
        credentials: "include",
      }
    );

    if (!response.ok) {
      throw new Error(
        `fetchGroupDashboard failed with status ${response.status}.`
      );
    }

    return (await response.json()) as GroupDashboardResponse;
  } catch {
    return {
      topRecommendedActions: [],
      riskFigures: {
        annualAverageLoss: {
          values: {
            lessThanOneMillion: 0,
            oneToFiveMillion: 0,
            fiveToTenMillion: 0,
            tenToTwentyMillion: 0,
            twentyToThirtyMillion: 0,
            thirtyToFiftyMillion: 0,
            fiftyToHundredMillion: 0,
            moreThanHundredMillion: 0,
          },
          oeNames: {
            lessThanOneMillion: [],
            oneToFiveMillion: [],
            fiveToTenMillion: [],
            tenToTwentyMillion: [],
            twentyToThirtyMillion: [],
            thirtyToFiftyMillion: [],
            fiftyToHundredMillion: [],
            moreThanHundredMillion: [],
          },
        },
        lossEventProbability: {
          values: {
            lessThanOneMillion: 0,
            oneToFiveMillion: 0,
            fiveToTenMillion: 0,
            tenToTwentyMillion: 0,
            twentyToThirtyMillion: 0,
            thirtyToFiftyMillion: 0,
            fiftyToHundredMillion: 0,
            moreThanHundredMillion: 0,
          },
          oeNames: {
            lessThanOneMillion: [],
            oneToFiveMillion: [],
            fiveToTenMillion: [],
            tenToTwentyMillion: [],
            twentyToThirtyMillion: [],
            thirtyToFiftyMillion: [],
            fiftyToHundredMillion: [],
            moreThanHundredMillion: [],
          },
        },
        highExposureLoss: {
          values: {
            lessThanOneMillion: 0,
            oneToFiveMillion: 0,
            fiveToTenMillion: 0,
            tenToTwentyMillion: 0,
            twentyToThirtyMillion: 0,
            thirtyToFiftyMillion: 0,
            fiftyToHundredMillion: 0,
            moreThanHundredMillion: 0,
          },
          oeNames: {
            lessThanOneMillion: [],
            oneToFiveMillion: [],
            fiveToTenMillion: [],
            tenToTwentyMillion: [],
            twentyToThirtyMillion: [],
            thirtyToFiftyMillion: [],
            fiftyToHundredMillion: [],
            moreThanHundredMillion: [],
          },
        },
      },
      numberOfOEs: 0,
    };
  }
}

export async function fetchProgram(): Promise<Program | undefined> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-program`, {
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as Promise<Program>;
  } catch {
    return;
  }
}

export async function createCRQRun(id: number): Promise<void> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-crq-run/${id}`, {
      credentials: "include",
      method: "POST",
    });

    if (!response.ok) {
      throw new Error(`createCRQRun failed with status ${response.status}.`);
    }
  } catch {
    return;
  }
}
