import { Search as SearchIcon } from "@mui/icons-material";
import { InputBase, InputBaseProps, styled } from "@mui/material";
import { ChangeEvent, FC } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "#ffffff",
  "&:hover": {
    backgroundColor: "#FCFCFC",
  },
  marginLeft: 0,
  width: "100%",
  height: "30px",
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.contrastText,
}));

interface CustomInputBaseProps extends InputBaseProps {
  placeholderTextColor?: string;
  makePlaceholderBold?: boolean;
}

const StyledInputBase = styled(InputBase)<CustomInputBaseProps>(
  ({ theme, placeholderTextColor, makePlaceholderBold }) => ({
    color: "inherit",
    height: "30px",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      height: "30px",
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
      "&::placeholder": {
        fontWeight: 500,
        color: placeholderTextColor || "#616161",
        opacity: makePlaceholderBold ? 1 : 0.5,
      },
    },
  })
);

export interface SearchBarV2Props {
  onChange: (value: string) => void;
  searchIconColor?: string;
  placeholderTextColor?: string;
  makePlaceholderBold?: boolean;
}

const SearchBarV2: FC<SearchBarV2Props> = ({
  onChange,
  placeholderTextColor,
  makePlaceholderBold = false,
}) => {
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon
          sx={{
            color: "#b0b0b0",
            background: "#ffffff",
          }}
        />
      </SearchIconWrapper>
      <StyledInputBase
        placeholderTextColor={placeholderTextColor}
        makePlaceholderBold={makePlaceholderBold}
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value);
        }}
      />
    </Search>
  );
};

export default SearchBarV2;
