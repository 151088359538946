import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  type ButtonProps,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { type FC, type PropsWithChildren, useEffect, useState } from "react";

import { ValidationTarget } from "..";
import { FormTab } from "../form-tab";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnsafeFormTab = FormTab<any, any, any>;

export type FormNavigationEntry = {
  className?: string;
  label: string;
  path: string;
  slot?: React.ReactNode;
};

export type FormNavigationAction =
  | "cancel"
  | "save"
  | "save-and-back"
  | "save-and-close"
  | "save-and-next"
  | "delete"
  | "confirm-up-to-date";
export type FormNavigationActionConfig = {
  onClick: (() => void) | (() => Promise<void>);
  key: FormNavigationAction;
  label: string;
  visible: boolean;
  overrideValidationTarget?: ValidationTarget;
  isCheckBoxChecked?: boolean;
  addCheckBoxToForm?: boolean;
} & ButtonProps;

export type FormNavigationWrapperProps = {
  actions: FormNavigationActionConfig[][];
  activeTab?: string;
  inDialog?: boolean;
  navigationSlot?: React.ReactNode;
  onTabSelected: (tabLabel: string) => void;
  tabs: UnsafeFormTab[];
  title?: string;
  inline?: boolean;
};

export const FormNavigationWrapper: FC<
  PropsWithChildren<FormNavigationWrapperProps>
> = ({
  actions,
  activeTab,
  children,
  inDialog,
  navigationSlot,
  onTabSelected,
  tabs,
  title,
  inline,
}) => {
  const [left, right] = actions;
  const titleHeight = inDialog ? 60 : 0;
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: !inDialog && !inline ? "calc(100vh - 130px)" : "100%",
      }}
    >
      {inDialog && (
        <Box
          sx={{
            height: `${titleHeight}px`,
            display: "flex",
            alignItems: "center",
            pt: "12px",
            pb: "12px",
            pl: "24px",
            borderBottom: `${theme.palette.secondary.main} 2px solid`,
          }}
        >
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height:
            !inDialog && !inline
              ? "calc(100vh - 130px)"
              : `calc(100% - ${titleHeight}px)`,
          padding: 0,
          margin: 0,
        }}
      >
        {!inline && (
          <Box
            sx={{
              background: theme.palette.secondary.main,
              display: "flex",
              flex: "0 0 340px",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "44px 40px",
            }}
          >
            <Box
              sx={{
                overflow: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              {tabs.map((tab) => {
                return (
                  <FirstLevelTab
                    tab={tab}
                    key={tab.id}
                    activeTab={activeTab}
                    onTabSelected={onTabSelected}
                  />
                );
              })}
            </Box>
            {navigationSlot && <Box>{navigationSlot}</Box>}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: "46px",
              overflowY: "auto",
              flex: "1 1 100%",
            }}
          >
            {children}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "12px 46px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              {left.map(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ({ key, overrideValidationTarget, visible, ...action }) =>
                  visible && (
                    <Button key={key} {...action}>
                      {action.label}
                    </Button>
                  )
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
              }}
            >
              {right.map(
                ({
                  key,
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  overrideValidationTarget,
                  visible,
                  isCheckBoxChecked,
                  ...action
                }) =>
                  visible &&
                  (key === "confirm-up-to-date" ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={action.onClick}
                          color="primary"
                          checked={isCheckBoxChecked}
                        />
                      }
                      label={action.label}
                      labelPlacement="start"
                      key={key}
                    />
                  ) : (
                    <Button key={key} {...action}>
                      {action.label}
                    </Button>
                  ))
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function FirstLevelTab({
  tab,
  activeTab,
  onTabSelected,
}: {
  tab: UnsafeFormTab;
  activeTab?: string;
  onTabSelected: (label: string) => void;
}) {
  const [areChildrenExpanded, setChildrenExpanded] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setChildrenExpanded(
      activeTab === tab.id ||
        tab.childTabs.map((tab) => tab.id).includes(activeTab ?? "")
    );
  }, [activeTab]);

  return (
    <>
      <Box
        onClick={() => onTabSelected(tab.id)}
        sx={{
          cursor: "pointer",
          height: "28px",
          fontSize: "16px",
          fontWeight: activeTab === tab.id ? 600 : 400,
          borderLeft: `${
            activeTab === tab.id
              ? theme.palette.primary.main
              : theme.palette.secondary.dark
          } 2px solid`,
          color: `${
            activeTab === tab.id ? theme.palette.primary.main : "#000000"
          }`,
          padding: "32px 4px 32px 11px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          transition: "border-left-color 0.3s",
        }}
      >
        {tab.label}
        {tab.childTabs?.length > 0 && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setChildrenExpanded(!areChildrenExpanded);
            }}
          >
            {areChildrenExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          maxHeight: areChildrenExpanded ? "200px" : 0,
          transition: "max-height 1s",
          overflow: "hidden",
        }}
      >
        {tab.childTabs?.map((childTab) => (
          <SecondLevelTab
            tab={childTab}
            key={childTab.id}
            onTabSelected={onTabSelected}
            activeTab={activeTab}
          />
        ))}
      </Box>
    </>
  );
}

function SecondLevelTab({
  tab,
  activeTab,
  onTabSelected,
}: {
  tab: UnsafeFormTab;
  activeTab?: string;

  onTabSelected: (id: string) => void;
}) {
  const theme = useTheme();
  return (
    <Box
      onClick={() => onTabSelected(tab.id)}
      sx={{
        cursor: "pointer",
        height: "10px",
        fontSize: "14px",
        fontWeight: activeTab === tab.id ? 600 : 400,
        borderLeft: `${
          activeTab === tab.id
            ? theme.palette.primary.main
            : theme.palette.secondary.dark
        } 2px solid`,
        color: `${
          activeTab === tab.id ? theme.palette.primary.main : "#000000"
        }`,
        padding: "16px 4px 16px 24px",
        display: "flex",
        alignItems: "center",
        transition: "border-left-color 0.3s",
      }}
    >
      {tab.label}
    </Box>
  );
}
