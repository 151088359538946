import { NotificationsOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export interface NotificationItem {
  id: string | number;
  title?: string;
  text?: string;
  createdAt?: string;
}

interface NotificationContentProps {
  notification: NotificationItem;
  onClick?: () => void;
}

export const NotificationContent = ({
  notification,
  onClick,
}: NotificationContentProps) => (
  <Box
    sx={{ display: "flex", gap: 2, p: 2, color: "text.primary" }}
    onClick={onClick}
  >
    <NotificationsOutlined sx={{ color: "primary.main" }} />
    <Box sx={{ flex: 1 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {notification.title}
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {notification.text}
      </Typography>
      {notification.createdAt && (
        <Typography variant="caption" color="text.secondary">
          {new Date(notification.createdAt).toLocaleString()}
        </Typography>
      )}
    </Box>
  </Box>
);
