import { Tune } from "@mui/icons-material";
import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  useTheme,
} from "@mui/material";
import React from "react";

import {
  GroupDashboardFilterDialog,
  GroupDashboardFilterDialogProps,
} from "../filter-dialog/group-dashboard-dialog";

export type GroupDashboardTitleCardSearchBarSlotProps = {
  handleFilterChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    trigger: string
  ) => void;
  clearAllFilters: () => void;
} & Pick<GroupDashboardFilterDialogProps, "filterData" | "regions">;

export const GroupDashboardTitleCardSearchBarSlot = ({
  clearAllFilters,
  filterData,
  handleFilterChange,
  regions,
}: GroupDashboardTitleCardSearchBarSlotProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [open, setOpen] = React.useState(false);
  const clickAwayHandler = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <Box
        id={"STATUS_BUTTON_ID"}
        data-testid={"STATUS_BUTTON_ID"}
        sx={{
          backgroundColor: theme.palette.info.light,
          color: "#122B54",
          marginRight: 1.5,
          cursor: "pointer",
          verticalAlign: "middle",
          borderRadius: "8px",
          p: 0.5,
          height: "30px",
          width: "30px",
        }}
        onClick={(e) => {
          setOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      >
        <Tune sx={{ height: "auto", width: "auto" }} />
      </Box>
      {open && (
        <ClickAwayListener
          onClickAway={clickAwayHandler}
          mouseEvent={"onMouseUp"}
        >
          <Popper
            data-testid={"reportingDate-popover"}
            open={open}
            anchorEl={anchorEl}
            sx={{
              backgroundColor: "background.paper",
              borderColor: theme.palette.primary.main,
              boxShadow: "0px 0px 5px grey",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 1,
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            <Box>
              <GroupDashboardFilterDialog
                onClick={() => {
                  setOpen(false);
                }}
                regions={regions}
                handleChange={handleFilterChange}
                filterData={filterData}
              />
              <Button
                onClick={clearAllFilters}
                sx={{
                  backgroundColor: "#0069d9",
                  height: "56px",
                  width: "300px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#0075d9",
                    borderColor: "#0062cc",
                    boxShadow: "none",
                  },
                }}
              >
                Clear All
              </Button>
            </Box>
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};
