import { ManageClusterEventPayload, Region } from "crq-types";

import { STRAPI_URL } from "../env";

export async function fetchOERegions(): Promise<Region[]> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-oe-region`, {
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    return (await response.json()) as Region[];
  } catch {
    return [];
  }
}

export async function createRegion(
  payload: ManageClusterEventPayload
): Promise<Region | undefined> {
  try {
    const { regionName } = payload;

    const response = await fetch(`${STRAPI_URL}/api/bff-oe-region`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        regionName,
      }),
    });

    if (!response.ok) {
      throw new Error(`Create Region failed with status ${response.status}.`);
    }
  } catch (error) {
    console.error("Error storing region:", error);
    return;
  }
}

export async function updateRegion(
  id: number,
  payload: ManageClusterEventPayload
): Promise<Region | undefined> {
  try {
    const { regionName } = payload;

    const response = await fetch(`${STRAPI_URL}/api/bff-oe-region/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        regionName,
      }),
    });

    if (!response.ok) {
      throw new Error(`Update Region failed with status ${response.status}.`);
    }

    return (await response.json()) as Region;
  } catch (error) {
    console.error("Error updating region:", error);
    return;
  }
}

export async function deleteRegion(id: number): Promise<void> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff-oe-region/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Delete Region failed with status ${response.status}.`);
    }
  } catch (error) {
    console.error("Error deleting region:", error);
  }
}
