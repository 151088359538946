import { SvgIconComponent } from "@mui/icons-material";
import { Badge, Box, IconButton, styled, Typography } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";

import type { ActionSidebarProps } from "../action-sidebar/action-sidebar";

/** Props for header action buttons */
export type HeaderActionButton = {
  /** MUI icon component to be displayed */
  icon: SvgIconComponent;
  /** Optional text to display next to the icon */
  text?: string;
  /** Badge count to display on the icon */
  badgeValue?: number;
  /** Click handler for the button */
  onClick?: () => void;
  /** Aria label for the button */
  ariaLabel?: string;
  /** Action sidebar support for the button */
  actionSidebar?: ActionSidebarProps;
};

/** Component for buttons that display both icon and text */
const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  padding: "8px",
  borderRadius: "8px",
  backgroundColor: theme.palette.secondary.light,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
  width: "auto",
  justifyContent: "center",
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.main,
    fontSize: "1.25rem",
  },
}));

/** Component for icon-only buttons */
const IconOnlyButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: "8px",
  borderRadius: "8px",
  minWidth: 40,
  width: "auto",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.primary.main,
  },
}));

const StyledBadge = styled(Badge)<{ hasText?: boolean }>(({ hasText }) => ({
  "& .MuiBadge-badge": {
    right: -9,
    top: hasText ? -7 : -4,
    padding: "0 4px",
    fontSize: "12px",
    height: "18px",
    minWidth: "18px",
  },
}));

/** Component for text with badge */
const TextContainer = styled(Box)({
  position: "relative",
});

export const HeaderActionButtonComponent: PimoReactComponent<
  HeaderActionButton
> = ({ icon: Icon, text, badgeValue, onClick, ariaLabel, ...props }) => {
  return text ? (
    <StyledBox onClick={onClick} {...props}>
      <Icon sx={{ marginRight: 0.5 }} />
      <TextContainer>
        <StyledBadge badgeContent={badgeValue} color="error" hasText>
          <Typography variant="body2" color="primary" fontWeight={600}>
            {text}
          </Typography>
        </StyledBadge>
      </TextContainer>
    </StyledBox>
  ) : (
    <IconOnlyButton
      onClick={onClick}
      aria-label={ariaLabel || text || "action button"}
      {...props}
    >
      <StyledBadge badgeContent={badgeValue} color="error">
        <Icon />
      </StyledBadge>
    </IconOnlyButton>
  );
};
